import React, { FC, useEffect, useRef } from "react";
import { ContentWrap } from "./styled.index";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const ContentTeam: FC = () => {
  const ContentWrapRef = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: ContentWrapRef?.current || "",
      toggleActions: "play none none none",
      start: "top+=25% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          ContentWrapRef?.current || null,
          0.75,
          { opacity: 0, y: 40, ease: 'Power.easeInOut' },
          { opacity: 1, y: 0, ease: 'Power.easeInOut' },
          "+=.1"
        ),
    });
  }, []);
  return (
    <ContentWrap ref={ContentWrapRef}>
      <p>
        DEVELOPMENT TEAM <br/>
        <span> Fortune International&nbsp;Group <br/> Oak Capital <br/> Fairwinds Group </span>
        <br />
        DESIGN ARCHITECT <br/>
        <span> Revuelta Architecture international </span>
        <br />
        INTERIOR DESIGNER <br/>
        <span>
          Lissoni
        </span><br />
        LANDSCAPE ARCHITECT <br/>
        <span>
          Concept Design by Piero Lissoni
        </span>
        <br />
        SALES <br/>
        <span> Fortune Development&nbsp;Sales </span>
        <br />
        Branding and creative <br/>
        <span> DBOX </span>
        <br />Renderings <br/>
        <span> DBOX <br/> Berga & GonzÁleZ </span>
      </p>
    </ContentWrap>
  );
};
export default ContentTeam;
