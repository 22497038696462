import React, {FC, useEffect, useRef} from "react";
import { ContentGridWrap, LeftCol } from "./index.styled";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";
import img1 from "../../assets/images/team/team_1_1.jpg"
import img2 from "../../assets/images/team/team_1_2.jpg"
import img3 from "../../assets/images/team/team_1_3.jpg"
import img4 from "../../assets/images/team/team_1_4.jpg"

const ContentGridSec: FC = () => {
  const img11Ref = useRef<HTMLDivElement>(null);
  const img12Ref = useRef<HTMLDivElement>(null);
  const img13Ref = useRef<HTMLDivElement>(null);
  const img14Ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ScrollTrigger.create({
      trigger: img11Ref.current || "",
      toggleActions: "play none none none",
      start: "top+=25% bottom",
      end: "bottom top",
      animation: gsap
        .timeline()
        .fromTo(
          img11Ref.current || "",
          2.5,
          { opacity: 0, y: 40 },
          { opacity: 1, y: 0 },
          "0.5"
        )
    });

    ScrollTrigger.create({
        trigger: img12Ref.current || "",
        toggleActions: "play none none none",
        start: "top+=25% bottom",
        end: "bottom top",
        animation: gsap
          .timeline()
        .fromTo(
          img12Ref.current || "",
          2.5,
          { opacity: 0, y: 40 },
          { opacity: 1, y: 0 },
          "0.5"
        )
    });

    ScrollTrigger.create({
      trigger:  img13Ref.current || "",
      toggleActions: "play none none none",
      start: "top+=25% bottom",
      end: "bottom top",
      animation: gsap
        .timeline()
        .fromTo(
          img13Ref.current || "",
          2.5,
          { opacity: 0, y: 40 },
          { opacity: 1, y: 0 },
          "0.5"
        )
    });

    ScrollTrigger.create({
      trigger:  img14Ref.current || "",
      toggleActions: "play none none none",
      start: "top+=25% bottom",
      end: "bottom top",
      animation: gsap
        .timeline()
        .fromTo(
          img14Ref.current || "",
          2.5,
          { opacity: 0, y: 40 },
          { opacity: 1, y: 0 },
          "0.5"
        )
    });
  }, []);
  return (
    <ContentGridWrap>
      <LeftCol>
        <div className="gridImg img_1" ref={img11Ref}>
          <img src={img1} alt="team image 1"/>
        </div>
        <div className="gridImg img_2" ref={img12Ref}>
          <img src={img2} alt="team image 2"/>
        </div>
        <div className="gridImg img_3" ref={img13Ref}>
          <img src={img3} alt="team image 3"/>
        </div>
        <div className="gridImg img_4" ref={img14Ref}>
          <img src={img4} alt="team image 4"/>
        </div>
      </LeftCol>
    </ContentGridWrap>
  );
};
export default ContentGridSec;
