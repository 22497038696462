import React, {FC, useEffect, useRef} from "react";
import { ContentGridWrap, LeftCol, RightCol } from "./index.styled";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";
import img1 from "../../assets/images/team/team_1.jpg"
import img2 from "../../assets/images/team/team_2.jpg"
import img3 from "../../assets/images/team/team_3.jpg"
import img4 from "../../assets/images/team/team_4.jpg"
import img5 from "../../assets/images/team/team_5.jpg"

const ContentGrid: FC = () => {
  const img1Ref = useRef<HTMLDivElement>(null);
  const img2Ref = useRef<HTMLDivElement>(null);
  const img3Ref = useRef<HTMLDivElement>(null);
  const img4Ref = useRef<HTMLDivElement>(null);
  const img5Ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ScrollTrigger.create({
      trigger: img1Ref.current || "",
      toggleActions: "play none none none",
      start: "top+=150px bottom",
      end: "bottom top",
      animation: gsap
        .timeline()
        .fromTo(
          img1Ref.current || "",
          2.5,
          { opacity: 0, y: 40 },
          { opacity: 1, y: 0 },
          "0.5"
        )
    });

    ScrollTrigger.create({
        trigger: img2Ref.current || "",
        toggleActions: "play none none none",
        start: "top+=150px bottom",
        end: "bottom top",
        animation: gsap
          .timeline()
        .fromTo(
          img2Ref.current || "",
          2.5,
          { opacity: 0, y: 40 },
          { opacity: 1, y: 0 },
          "0.5"
        )
    });

    ScrollTrigger.create({
      trigger:  img3Ref.current || "",
      toggleActions: "play none none none",
      start: "top+=150px bottom",
      end: "bottom top",
      animation: gsap
        .timeline()
        .fromTo(
          img3Ref.current || "",
          2.5,
          { opacity: 0, y: 40 },
          { opacity: 1, y: 0 },
          "0.5"
        )
    });

    ScrollTrigger.create({
      trigger:  img4Ref.current || "",
      toggleActions: "play none none none",
      start: "top+=150px bottom",
      end: "bottom top",
      animation: gsap
        .timeline()
        .fromTo(
          img4Ref.current || "",
          2.5,
          { opacity: 0, y: 40 },
          { opacity: 1, y: 0 },
          "0.5"
        )
    });

    ScrollTrigger.create({
      trigger:  img5Ref.current || "",
      toggleActions: "play none none none",
      start: "top+=150px bottom",
      end: "bottom top",
      animation: gsap
        .timeline()
        .fromTo(
          img5Ref.current || "",
          2.5,
          { opacity: 0, y: 40 },
          { opacity: 1, y: 0 },
          "0.5"
        )
    });

  }, []);
  return (
    <ContentGridWrap>
      <LeftCol>
        <div className="gridImg img_1" ref={img1Ref}>
          <img src={img1} alt=""/>
        </div>
        <div className="gridImg img_2" ref={img2Ref}>
          <img src={img2} alt=""/>
        </div>
        <div className="gridImg img_3" ref={img3Ref}>
          <img src={img3} alt=""/>
        </div>
        <div className="gridImg img_4" ref={img4Ref}>
          <img src={img4} alt=""/>
        </div>
      </LeftCol>
      <RightCol>
        <div className="gridImg img_5" ref={img5Ref}>
          <img src={img5} alt=""/>
        </div>
      </RightCol>
    </ContentGridWrap>
  );
};
export default ContentGrid;
