import * as React from "react";
import { seoData } from "../data/seo";
import { introData } from "../data/intro";
import { navData } from "../data/nav";
import { textData } from "../data/text";
import Seo from "../components/seo";
import IntroVideo from "../components/intro-video";
import Image from "../components/image";
import ContentNextPage from "../components/content-next-page";
import Footer from "../components/footer";
import ContentGrid from "../components/content-grid";
import ContentGridSec from "../components/content-grid-sec";
import ContentTextFull from "../components/content-text-full";
import ContentTextImg from "../components/content-text-img";
import ContentTeam from "../components/team/content-team";
import Composition from "../components/composition";
import useWindowSize from "../helpers/UseWindowSize";
import NewComposition from "../components/composition/test";
import {CompositionWrapper} from "../components/composition/test/styled.index";
import leftPalms5 from "../assets/images/compos/marina_compos_5_left.png";
import rightRight7 from "../assets/images/compos/marina_compos_7_right.png";


const TeamPage = () => {
  const {width} = useWindowSize();
  if (typeof window === `undefined`) {
    return(<></>);
  }
  return (
    <main>
      <Seo page={seoData.team} />
      <IntroVideo page={introData.team}/>
      <ContentTextFull
        label={textData.team1.label}
        heading={textData.team1.heading}
        text={textData.team1.text}
      />
      { width <= 600 && <Image imageName={"team_g1_mob.png"}/>}
      { width >= 1024 && <ContentGrid />}
      <ContentTextImg
        img1={width >= 1024 ? "team_6.jpg" : "team_g4_mob.png"}
        img2={width >= 600 ? "team_7.jpg" : ""}
        label={textData.team2.label}
        heading={textData.team2.heading}
        text={textData.team2.text}
        reversed={textData.team2.reversed}
      />
      <ContentTextFull
        label={textData.team3.label}
        heading={textData.team3.heading}
        text={textData.team3.text}
      />
      { width <= 600 && <Image imageName={"team_g3_mob.png"} paddingMob={'0'}/>}
      { width > 600 && <ContentGridSec/>}
      <ContentTextFull
        label={textData.team5.label}
        heading={textData.team5.heading}
        text={textData.team5.text}
        type={'narrow'}
      />
      <section>
        <ContentTextImg
          img1={"team_g3.jpg"}
          img2={""}
          label={textData.team4.label}
          heading={textData.team4.heading}
          text={textData.team4.text}
        />
        <ContentTeam />
        {width < 600 && <Composition imgName={"team_compos_1_mob.png"} align={"offBottom3"} />}
        {width >= 1024 && <NewComposition imgSrc={leftPalms5} bottom={'2vh'} left={'0'} height={'24vh'} zIndex={'1'}/>}
        {width >= 1024 && <NewComposition imgSrc={rightRight7} bottom={'1vh'} right={'0'} height={"120vh"} zIndex={'1'}/>}
      </section>
      <CompositionWrapper>
        <ContentNextPage
          type={"bg-full"}
          nextLink={navData.press.link}
          nextTitle={navData.press.title}
          vertAlign={'center'}
          mobHeight={"40vh"}
        />

        <NewComposition
          shape={"circle"}
          top={"0"}
          right={"93vw"}
          height={"600px"}
          mobBreakpoint={"phoneXL"}
          mobTop={"-10%"}
          mobRight={"90vw"}
          mobHeight={"100%"}
        />
        <NewComposition
          shape={"triangle"}
          top={"0"}
          right={"0"}
          height={"100%"}
          mobBreakpoint={"phoneXL"}
          mobTop={"auto"}
          mobBottom={"0"}
          mobRight={"0"}
          mobHeight={"80%"}
        />
      </CompositionWrapper>
      <Footer />
    </main>
  );
};

export default TeamPage;
