import styled from "styled-components";

export const ContentGridWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .gridImg {
    padding-right: 8px;
    padding-bottom: 8px;
    img {
    }
  }
  .img_1 {
    width: 61.5%;
  }
  .img_2 {
    width: 38.5%;
  }
  .img_3 {
    width: 37.12%;
  }
  .img_4 {
    width: 62.88%;
  }
  .img_5 {
    max-height: max-content;
    object-fit: cover;

  }
`;

export const LeftCol = styled.div`
  width: 76.5%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

`;
export const RightCol = styled.div`
  width: 24%;
  display: flex;
  align-items: stretch;
`;
