import styled from "styled-components";
import { cssLock } from "../../../helpers/Mixins";
import {mediaMax} from "../../../helpers/MediaQueries";

export const ContentWrap = styled.div`
  display: flex;
  width: 100%;
  padding: 9% 7.2% 9.3% 0;
  min-height: 130vh;
  justify-content: center;
  ${mediaMax.phoneXL`
    padding: 0 12%;
  `}
  p {
    width: 89%;
    max-width: 1300px;
    padding-top: 5%;
    text-transform: uppercase;
    color: #a29b8d;
    letter-spacing: 0.48px;
    ${cssLock({
      minSize: 18,
      maxSize: 20,
      minWidth: 375,
      maxWidth: 1920,
      property: "font-size",
      unit: "px",
    })};
    ${cssLock({
      minSize: 30,
      maxSize: 40,
      minWidth: 375,
      maxWidth: 1920,
      property: "line-height",
      unit: "px",
    })};
    ${mediaMax.phoneXL`
      width: 100%;
    `}
    span {
      color: #5F5F5F;
      letter-spacing: 0.6px;
      margin-bottom: 15px;
      display: inline-block;
      ${cssLock({
        minSize: 24,
        maxSize: 30,
        minWidth: 375,
        maxWidth: 1920,
        property: "font-size",
        unit: "px",
      })};
    }
  }
`;
