import styled from "styled-components";
import {mediaMax} from "../../helpers/MediaQueries";
export const ContentTextWrap = styled.div`
  padding: 9% 7.2% 9.3% 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  position: relative;
  ${mediaMax.tabletLandscape`
    flex-direction: column;
    padding: 0 12%;
  `};
`;
export const Heading = styled.div`
  width: 44%;
  max-width: 650px;
  ${mediaMax.tabletLandscape`
      width: 100%;
  `};
  h2 {
    padding-top: 26px;
  }
  ${mediaMax.tabletLandscape`
    padding: 15% 0 0 0;
      h2 {
        padding: 26px 0;
      }
  `};
`;

export const Description = styled.div`
  width: 45%;
  padding: 0 20px;
  min-width: 425px;
  max-width: 650px;
  ${mediaMax.tabletLandscape`
    min-width: 225px;
    width: 100%;
    padding: 0 0 15% 0;
  `};
`;
