import React, { FC, useEffect, useRef } from "react";

import { ContentTextWrap, Heading, Description } from "./styled.index";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

interface IContentText {
  bgClassName?: string;
  heading?: string | React.ReactFragment;
  text?: string | React.ReactElement;
  label?: string | React.ReactElement;
  type?: string;
}

const ContentTextFull: FC<IContentText> = ({
  bgClassName,
  heading,
  text,
  label,
  type
}) => {
  const ContentWrapRef = useRef<HTMLDivElement>(null);
  const headingRef = useRef<HTMLDivElement>(null);
  const descRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: ContentWrapRef?.current || "",
      toggleActions: "play none none none",
      start: "top+=25% bottom",
      end: "center top",
      animation: gsap
        .timeline()
        .fromTo(
          headingRef?.current || null,
          2.5,
          { opacity: 0, ease: 'Power.easeInOut' },
          { opacity: 1, ease: 'Power.easeInOut' },
        )
        .fromTo(
          descRef?.current || null,
          .5,
          { opacity: 0, y: 40, ease: 'Power.easeInOut' },
          { opacity: 1, y: 0, ease: 'Power.easeInOut' },
          "-=1.8"
        ),
    });
  }, []);

  return (
    <ContentTextWrap className={bgClassName} ref={ContentWrapRef}>
      <Heading ref={headingRef}>
        {label && <span className={"heading fs-lbl"}>{label}</span>}
        {heading && <h2 className={"heading fs-lbl-xl"}>{heading}</h2>}
      </Heading>
      <Description ref={descRef}>
        {text && <p className={`reg-text ${type}`}>{text}</p>}
      </Description>
    </ContentTextWrap>
  );
};
export default ContentTextFull;
