import styled from "styled-components";
import {mediaMax} from "../../helpers/MediaQueries";

interface IProps {
  reversed?: boolean;
}
export const ContentWrap = styled.div<IProps>`
  padding: 9% 7.2% 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  ${mediaMax.tabletLandscape`
      padding: ${(props:any) => props.reversed? "11.4% 0 0" : "0 0 11.4%"};
  `};
  ${mediaMax.tablet`
    flex-direction: ${(props:any) => props.reversed? "column-reverse" : "column"};
  `};
`;
export const Images = styled.div<IProps>`
  width: 44%;
  
  ${mediaMax.tablet`
      width: 100%;
      margin-top: ${(props:any) => props.reversed? "11.4%" : "0"};
      margin-bottom: ${(props:any) => props.reversed? "0" : "11.4%"};
  `};
  &.rev {
    display: flex;
    div:first-child {
      padding-right: 6px;
    }
  }
  div:first-child {
    padding-bottom: 10px;
    ${mediaMax.phoneXL`
      padding-bottom: 0;  
    `}
  }
`;

export const Description = styled.div`
  width: 45%;
  padding: 0 20px;
  min-width: 425px;
  max-width: 650px;
  margin-left: 5.5%;

  ${mediaMax.tablet`
    min-width: 225px;
    width: 100%;
    padding: 0 12%;
    margin-left: 0%;
    max-width: 800px;
  `};
  h2 {
    padding: 20px 0 26px;
  }
`;
