import styled from "styled-components";

export const ContentGridWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .gridImg {
    padding-right: 8px;
    padding-bottom: 8px;
    img {
    }
  }
  .img_1 {
    width: 41.7%;
  }
  .img_2 {
    width: 57.7%;
  }
  .img_3 {
    width: 70%;
  }
  .img_4 {
    width: 30%;
  }
`;

export const LeftCol = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`;
